import React from 'react';
import Header from './components/Header/Header';
import OrderPage from './components/OrderPage';
import HomePage from './components/HomePage/HomePage';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


function App() {
    return (
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/order/:id" element={<OrderPage />} />
            </Routes>
        </Router>
    );
}

export default App;
