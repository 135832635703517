import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './OrderPage.css';

const OrderPage = () => {
    const { id } = useParams();
    const [orderData, setOrderData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`https://server.avtorancho.com.ua/order/${id}`)
            .then(response => {
                setOrderData(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching order:', error);
                setLoading(false);
            });
    }, [id]);

    if (loading) return <div className="loading">Loading...</div>;

    if (!orderData) {
        return (
            <div className="order-not-found">
                
                <div className="order-not-found-message">
                    ORDER NOT FOUND
                </div>
            </div>
        );
    }

    const { title, code, code2, description, category, geo, quantity, price, keyword, photos, isActivated, activatedBy, model, brand, qrcode } = orderData;

    return (
        <div className="order-page">
            
            <h1>{title}</h1>

            <Carousel showThumbs={false} infiniteLoop useKeyboardArrows>
                {photos.map((photo, index) => (
                    <div key={index}>
                        <img src={photo} alt={`Order image ${index + 1}`} />
                    </div>
                ))}
            </Carousel>

            <div className="qr-code-section">
                <h3>QR Code</h3>
                {qrcode && <img src={qrcode} alt="QR Code" className="qr-code" />}
            </div>

            <div className="order-details">
                <p><strong>Code:</strong> {code}</p>
                <p><strong>Code 2:</strong> {code2}</p>
                <p><strong>Description:</strong> {description}</p>
                <p><strong>Category:</strong> {category}</p>
                <p><strong>Geo:</strong> {geo}</p>
                <p><strong>Quantity:</strong> {quantity}</p>
                <p><strong>Price:</strong> ${price}</p>
                <p><strong>Keyword:</strong> {keyword}</p>
                <p><strong>Activated:</strong> {isActivated ? 'Yes' : 'No'}</p>
                <p><strong>Activated By:</strong> {activatedBy}</p>
                <p><strong>Model:</strong> {model}</p>
                <p><strong>Brand:</strong> {brand}</p>
            </div>
        </div>
    );
};

export default OrderPage;
